<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="PROJECTS"
          :breadcrumb="[
            {
              label: 'professional',
            },
            { label: 'Projects' },
            { label: 'Project List' },
          ]"
        >
        </banner>
        <div class="row" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <!-- pc responsive -->
            <div
              class="card custom-card d-none d-md-block d-xl-block"
              style="font-family: 'Poppins', sans-serif"
            >
              <div class="card-body">
                <h4
                  style="
                    color: #00364f;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                  "
                >
                  <b
                    v-if="project.service"
                    style="
                      color: #00364f;
                      font-family: 'Poppins', sans-serif;
                      font-size: 1.2rem;
                    "
                  >
                    {{ project.service.name }}</b
                  >
                </h4>

                <strong> Modules: </strong>
                <span
                  style="text-transform: uppercase"
                  v-for="(module1, index) in serviceModules"
                  :key="index"
                  >{{ module1.moduleName }}, &nbsp;</span
                >
                <div class="row" style="margin-left: 0px; margin-right: 0px">
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Code</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ project.clientServiceNo }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Project Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ dateTime(project.date) }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Phone No.</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.phone }}</strong></span
                    >
                  </div>

                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Email</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.email }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>&nbsp;&nbsp;Due Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ dateTime(project.dueDate) }}</strong></span
                    >
                  </div>
                  <!-- <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div> -->

                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Status </b>
                    </h4>

                    <p
                      v-if="project.status == 'pending'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: gray;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>New</b>
                    </p>
                    <p
                      v-if="project.status == 'assigned'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #349c9e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'completed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #3ab04b;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'processed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ffaa00;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'hold'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #8f8d8e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'rejected'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: black;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'cancelled'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #f50303;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'upgrade'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ee0def;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- mobile responsive -->
            <div
              class="card custom-card d-block d-sm-block d-xl-none d-md-none"
              style="font-family: 'Poppins', sans-serif"
            >
              <div class="card-body">
                <h4
                  style="
                    color: #00364f;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                  "
                >
                  <b
                    v-if="project.service"
                    style="
                      color: #00364f;
                      font-family: 'Poppins', sans-serif;
                      font-size: 1.2rem;
                    "
                  >
                    {{ project.service.name }}</b
                  >
                </h4>

                <strong> Modules: </strong>
                <span
                  style="text-transform: uppercase"
                  v-for="(module1, index) in serviceModules"
                  :key="index"
                  >{{ module1.moduleName }}, &nbsp;</span
                >
                <div class="row mt-1">
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Code</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ project.clientServiceNo }}</strong></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Project Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{ dateTime(project.date) }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Phone No.</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.phone }}</strong></span
                    >
                  </div>

                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Email</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.email }}</strong></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>&nbsp;&nbsp;Due Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{ dateTime(project.dueDate) }}</b></span
                    >
                  </div>
                  <!-- <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div> -->
                  <!-- <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share </b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div> -->
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Status </b>
                    </h4>

                    <p
                      v-if="project.status == 'pending'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: gray;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>New</b>
                    </p>
                    <p
                      v-if="project.status == 'assigned'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #349c9e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'completed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #3ab04b;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'processed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ffaa00;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'hold'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #8f8d8e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'rejected'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: black;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'cancelled'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #f50303;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'upgrade'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ee0def;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- end resposnive -->
          </div>
        </div>

        <div class="row" style="margin-top: -17.5px">
          <div class="col-xl-7 col-md-7 col-sm-7" style="padding-right: 8px">
            <div class="card custom-card" style="height: 100%">
              <div class="card-body pt-2">
                <h3 style="color: black">
                  <b>Message & Notification History</b>
                </h3>
                <div style="overflow-y: auto; height: 400px; overflow-x: none">
                  <div
                    v-for="(comment, index) in comments"
                    :key="index"
                    class="row mb-1 radius"
                    style="
                      background-color: #f5f5f5;

                      border-radius: 10px;
                      padding: 10px 10px 10px 10px;
                    "
                  >
                    <!-- <div class="col-xl-1 col-2">
                    
                    
                  </div> -->
                    <div class="col-xl-12">
                      <img
                        style="width: 30; height: 30px; border-radius: 100%"
                        :src="findImgUsedr(comment.user, comment.user_type)"
                        alt=""
                      />
                      &nbsp; &nbsp;

                      <b style="color: #051eff" v-if="comment.user.role_id == 1">{{
                        comment.user.name
                      }} ({{
                        comment.user_type
                      }})</b>
                      <b style="color: #0170b9" v-if="comment.user.role_id == 2">{{
                        comment.user.name
                      }} ({{
                        comment.user_type
                      }})</b>
                      <b style="color: #f21000" v-if="comment.user.role_id == 3">{{
                        comment.user.name
                      }} ({{
                        comment.user_type
                      }})</b>
                      <b style="color: #af05f2" v-if="comment.user.role_id == 8">{{
                        comment.user.name
                      }} ({{
                        comment.user_type
                      }})</b>
                      &nbsp;

                      <small style="color: #4c4c4c; text-transform: uppercase">
                        {{ comment.time }}, {{ dateTime(comment.date) }}</small
                      >

                      <p
                        style="
                          color: 000000;
                          font-size: 14px;
                          padding: 10px 10px 10px 10px;
                          text-align: justify;
                        "
                      >
                        {{ comment.comment }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-md-5 col-sm-5" style="padding-left: 8px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <!-- for Upgrade  -->
            <div
              v-if="upgradesData.length != 0"
              class="card custom-card"
              style="padding: 0px 0px 0px 0px"
            >
              <div class="card-body">
                <div class="row">
                  <span style="color: #00364f"><strong>Request Upgrade</strong></span>

                  <div class="col-12 pt-1">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm" style="font-size: 14px">
                        <tr
                          class="text tr-head table-wrapper-scroll-y rounded-circle"
                          style="background-color: white"
                        >
                          <th
                            class="text-truncate"
                            style="
                              background-color: #cdcdcd;
                              border-radius: 8px 0px 0px 8px;
                            "
                          >
                            Title
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Date
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Amount
                          </th>

                          <th
                            style="background-color: #cdcdcd"
                            class="text-truncate text-center"
                          >
                            Status
                          </th>
                          <!-- <th
                            style="
                              background-color: #cdcdcd;
                              border-radius: 0px 8px 8px 0px;
                            "
                            class="text-truncate text-end"
                          >
                            Action
                          </th> -->
                        </tr>

                        <tbody class="text">
                          <tr v-for="(upgrade, index) in upgradesData" :key="index">
                            <td class="text-truncate">{{ upgrade.title }}</td>
                            <td class="text-truncate">{{dateTime(upgrade.date) }}</td>
                            <td class="text-truncate">{{ upgrade.amt }}</td>
                            <td class="text-truncate">
                              <span
                                v-if="upgrade.status == 'approved'"
                                style="
                                  width: 80px;
                                  background-color: #430194;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'paid'"
                                style="
                                  width: 80px;
                                  background-color: green;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'canceled'"
                                style="
                                  width: 80px;
                                  background-color: #f21000;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'pending'"
                                style="
                                  width: 80px;
                                  background-color: orange;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>
                              <span
                                v-if="upgrade.status == 'reject'"
                                style="
                                  width: 80px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>
                            </td>
                            <!-- <td class="text-truncate">
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="..."
                              >
                                <span
                                  style="
                                    background-color: blue;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                  @click="
                                    openWindow(
                                      `${
                                        this.$store.state.imgUrl +
                                        '/clientservice/' +
                                        this.$route.params.id +
                                        '/' +
                                        upgrade.document
                                      }`
                                    )
                                  "
                                >
                                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                                </span>

                                <span
                                  @click="upgradeDataAsign(upgrade)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#upgradeModel"
                                  style="
                                    background-color: green;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                >
                                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </span>
                                <span
                                  @click="upgradeDelete(upgrade)"
                                  style="
                                    background-color: red;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                >
                                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </span>
                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- for Upgrade  -->
            <div v-if="project.staff" class="card custom-card" :style="(upgradesData.length != 0) ? 'margin-top:-17px' : ''">
              <div class="card-body">
                <div class="row radius" style="line-height: 1.3em; border-radius: 10px">
                  <div class="col">
                    <!-- mobile responsive -->
                    <img
                      class="d-xl-none d-md-none d-block"
                      style="
                        width: 100%;
                        height: 100%;
                        margin-left: -10px;
                        border-radius: 100%;
                      "
                      :src="findImgUsedr(project.staff, 'staff')"
                      alt=""
                    />
                    <!-- pc responsive -->
                    <img
                      class="d-xl-block d-md-block d-none"
                      style="
                        width: 80%;
                        height: 85%;
                        border-radius: 100%;
                        margin-left: -10px;
                      "
                      :src="findImgUsedr(project.staff, 'staff')"
                      alt=""
                    />
                  </div>
                  <div class="col-10">
                    <b style="color: #f21300">Vakilgiri Expert</b>
                    <div class="text">
                      <b>Name : </b>
                      <span v-if="project.staff"> {{ project.staff.name }}</span>
                    </div>
                    <div class="text">
                      <b>Contact Number : </b>
                      <span v-if="project.staff"> +91- {{ project.staff.phone }} </span>
                    </div>
                    <div class="text">
                      <b>Email : </b>
                      <span v-if="project.staff"> {{ project.staff.email }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div
              class="card radius"
              style="margin-top: -17.5px; box-shadow: 1px 1px 5px 1px gray"
            >
              <div class="card-body">
                <div class="row radius" style="line-height: 1.3em; border-radius: 10px">
                  <div class="col">
                   
                    <img
                      class="d-xl-none d-md-none d-block"
                      style="
                        width: 100%;
                        height: 100%;
                        margin-left: -10px;
                        border-radius: 100%;
                      "
                      src="https://apk.mudhrape.com/wp-content/uploads/2022/03/WhatsApp-Image-2020-01-05-at-12.07.40-AM-Copy-150x150-1.webp"
                      alt=""
                    />
               
                    <img
                      class="d-xl-block d-md-block d-none"
                      style="
                        width: 80%;
                        height: 85%;
                        border-radius: 100%;
                        margin-left: -10px;
                      "
                      src="https://apk.mudhrape.com/wp-content/uploads/2022/03/WhatsApp-Image-2020-01-05-at-12.07.40-AM-Copy-150x150-1.webp"
                      alt=""
                    />
                  </div>
                  <div class="col-10">
                    <b style="color: #0170b9">Retailer</b>
                    <div class="text">
                      <b>Name : </b>
                      <span v-if="project.retailer"> {{ project.retailer.name }}</span>
                    </div>
                    <div class="text">
                      <b>Contact Number : </b>
                      <span v-if="project.retailer">
                        +91- {{ project.retailer.phone }}
                      </span>
                    </div>
                    <div class="text">
                      <b>Email : </b>
                      <span v-if="project.retailer"> {{ project.retailer.email }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div
              class="card radius"
              style="margin-top: -17.5px; box-shadow: 1px 1px 5px 1px gray"
            >
              <div class="card-body font">
                <h4><b style="color: black">Update Status</b></h4>
                <div class="row">
                  <div class="col-xl-9 col-md-9 col-sm-9 font" style="padding-right: 5px">
                    <select
                      class="form-select border-dark text"
                      style="border-radius: 10px"
                    >
                      <option selected>Open this select menu</option>
                      <option value="1">Completed</option>
                      <option value="2">Upgrade</option>
                      <option value="3">Cancelled</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-sm-3 col-md-3">
                    <button
                      type="button"
                      class="btn text-white btns form-control"
                      style="border-radius: 10px"
                    >
                      Request
                    </button>
                  </div>
                </div>
                <div class="row mt-2 text">
                  <div class="col">
                    <h4><b style="color: black">Upload Invoice</b></h4>
                    <font-awesome-icon icon="remove" style="color: red" />
                    <span>INV102205</span>
                  </div>
                  <div class="col-3 text-end">
                    <button
                      type="button"
                      class="btn text-white btns"
                      style="background-color: red; padding-top: 5px; padding-bottom: 5px"
                    >
                      <b>Upload</b>
                    </button>
                  </div>
                </div>
              </div>
            </div> -->

            <div
              v-if="Tasks.length != 0"
              class="card custom-card"
              style="margin-top: -17.5px"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-3 col-md-3 col-sm-3">
                    <b
                      class="font"
                      style="color: black; font-weight: 600; font-size: 16px"
                      >Tasks</b
                    >
                  </div>
                  <div class="col-xl-9 col-md-9 col-sm-9">
                    <div class="progress">
                      <div
                        id="progressBarProject"
                        class="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-if="Tasks.length != 0"
                        :style="`width: ${this.progressForProject()}%;`"
                      >
                        {{ taskProgress }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div>
                      <div
                        class="form-check mt-1"
                        v-for="(task, index) in Tasks"
                        :key="index"
                      >
                        <input
                          v-if="task.status == 'complete'"
                          class="form-check-input"
                          disabled
                          type="checkbox"
                          checked
                        />
                        <input v-else class="form-check-input" disabled type="checkbox" />
                        <label style="color: #00364f; font-weight: 600">
                          <span v-if="task.step"> {{ task.step.title }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="requestdocuments.length != 0"
              class="card custom-card"
              style="margin-top: -17.5px"
            >
              <div class="card-body font">
                <div class="row text">
                



                <div class="col-12">
                  <h4><b style="color: black">Requested Documents</b></h4>
                  <div class="container-fluid table-scroll">
                    <table class="table table-hover table-sm" style="font-size: 14px">
                      <tr
                        class="text tr-head table-wrapper-scroll-y rounded-circle"
                        style="background-color: white"
                      >
                        <th
                          class="text-truncate"
                          style="
                            background-color: #cdcdcd;
                            border-radius: 8px 0px 0px 8px;
                          "
                        >
                          +
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Title
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Doccument
                        </th>
                      

                        <th
                          style="
                            background-color: #cdcdcd;
                            border-radius: 0px 8px 8px 0px;
                          "
                          class="text-truncate text-end"
                        >
                        Remark
                        </th>
                      </tr>

                      <tbody class="text">
                        <tr v-for="(clentDocc, index) in requestdocuments" :key="index">
                          <td class="text-truncate">
                            <span v-if="clentDocc.status == 'complete'">
                              <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style="color: green"
                      ></i>
                            </span>
                            <span v-else>
                              <font-awesome-icon icon="remove" style="color: red" />
                            </span>
                          
                          </td>
                          <td class="text-truncate">{{ clentDocc.title }}</td>
                          <td class="text-truncate">
                            <span v-if="clentDocc.status == 'complete'">
                            
                              
                              <span @click="openWindowClientDoccRequest(clentDocc)"
                        ><i class="fa fa-download" aria-hidden="true"></i></span
                    >


                            </span>
                          </td>
                          <td class="text-truncate">{{ clentDocc.description }}</td>
                         
                        
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              </div>
            </div>

            <div class="card custom-card" v-if="doccUploadByretailer.length != 0" :style="(requestdocuments.length != 0) ? 'margin-top:-17' : ''">
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
               
                "
              >
                <div class="col-md-8 text-white">
                  <h4><b class="text-white">DOCS UPLOADED BY RETAILER</b></h4>
                </div>
                <div class="col-md-4 text-end">
                  <!-- <button
                    type="button"
                    class="btn btns text-white"
                    style="
                      background-color: #f21300;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                  >
                    <b>Upload</b>
                  </button> -->
                </div>
              </div>
              <div class="card-body pt-1">
                <div
                  class="row"
                  style="padding-top: 3px"
                  v-for="(docc, index) in doccUploadByretailer"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b v-if="docc.document">{{ docc.caption }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                    <!-- <b v-if="docc.document"
                      ><a :href="downloadImg(docc)" style="color: red"> Download </a></b
                    > -->

                    <span
                      @click="
                      downloadImg(docc)
                      "
                      ><b style="color: red; cursor: pointer">Download</b></span
                    >
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <b class="d-xl-none d-md-none d-block" style="color: red">Download</b>
                    <small> {{ dateTime(docc.created_at) }}</small>
                  </div>
                  <hr style="margin-top: -1px" />
                </div>
              </div>
            </div>

            <div class="card custom-card" v-if="allDoccs.length != 0">
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                  margin-top: -17.5px;
                "
              >
                <b class="text-white">DOCS UPLOADED BY PROFESSIONAL</b>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  style="padding-top: 3px"
                  v-for="(certificate, index) in allDoccs"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b>{{ certificate.title }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                    <span
                      @click="
                        openWindow(
                          `${
                            this.$store.state.imgUrl +
                            '/clientservice/' +
                            this.$route.params.id +
                            '/' +
                            certificate.certificate
                          }`
                        )
                      "
                      ><b style="color: red; cursor: pointer">Download</b></span
                    >
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <b class="d-xl-none d-md-none d-block" style="color: red">Download</b>
                    <small> {{ dateTime(certificate.date) }}</small>
                  </div>
                  <hr style="margin-top: -1px" />
                </div>
              </div>
            </div>

            <div class="card custom-card d-none" v-if="challans.length != 0">
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                  margin-top: -17.5px;
                "
              >
                <h4><b class="text-white">CHALANS</b></h4>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  style="padding-top: 2px"
                  v-for="(challan, index) in challans"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b>{{ challan.title }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                    <b class="d-xl-block d-md-block d-none">{{ challan.challanNo }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <small>Amount : Rs.{{ challan.amt }}</small
                    ><br />
                    <small style="color: red; cursor: pointer"
                      ><b
                        ><span
                          @click="
                            openWindow(
                              `${
                                this.$store.state.imgUrl +
                                '/clientservice/' +
                                this.$route.params.id +
                                '/' +
                                challan.challan
                              }`
                            )
                          "
                          >Download</span
                        >
                        <!-- |
                        <span @click="deleteChallans(challan)">Delete</span> -->
                      </b></small
                    ><br />
                    <small class="d-xl-none d-md-none d-block">{{
                      challan.challanNo
                    }}</small>
                    <small>{{ challan.date }} </small>
                  </div>
                  <hr />
                </div>
              </div>
              <div
                class="card-footer text-white"
                style="
                  background-color: #00364f;
                  border-radius: 0px 0px 10px 10px;
                  padding: 8px 5px 8px 5px;
                "
              >
                <div class="row">
                  <div class="col-9 text-start"><b>Total Amount</b></div>

                  <div class="col-3 text-end">
                    <b>Rs. {{ totalAmtChallan }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- model  -->
  <!-- Button trigger modal -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="documentsByPro"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">DOCS UPLOADED BY PROFESSIONAL</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createCertificateByPrfesional()">
            <div class="row">
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="certificateForm.date"
                  placeholder="date"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="certificateForm.title"
                  placeholder="Title"
                />
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Certificate No</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="certificateForm.certificateNo"
                  placeholder="Certificate No"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>certificate Athority</strong></label>

                <select
                  v-model="certificateForm.certificateAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.amt"
                  placeholder="Amount"
                />
              </div> -->
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Certificate</strong></label>
                <!-- v-model="chalansForm.challan" -->
                <input
                  type="file"
                  class="form-control"
                  @change="UploadCertificate"
                  placeholder="challan"
                  id="certificate"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Expire Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="chalansForm.expireDate"
                  placeholder="expireDate"
                />
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="certificateForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
              </div>

              <!--
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Validity</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.validity"
                  placeholder="validity"
                />
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="upgradeModel"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong>Upgrade </strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="upgradeEdit == 'false' ? createUpgrade() : updateUpgrade()"
          >
            <div class="row">
              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="upgradeForm.title"
                  placeholder="Title"
                />
              </div>
              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Document</strong></label>
                <input
                  type="file"
                  class="form-control"
                  @change="uploadUpgrade"
                  placeholder="Title"
                  id="uploadUpgrade"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="upgradeForm.date"
                  placeholder="date"
                />
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan Athority</strong></label>

                <select
                  v-model="chalansForm.challanAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
              </div> -->

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="upgradeForm.amt"
                  placeholder="Amount"
                />
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="upgradeForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="challansModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong>Challan </strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createChalans()">
            <div class="row">
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="chalansForm.date"
                  placeholder="date"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.title"
                  placeholder="Title"
                />
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan No</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.challanNo"
                  placeholder="Challan No"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan Athority</strong></label>

                <select
                  v-model="chalansForm.challanAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.amt"
                  placeholder="Amount"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan</strong></label>
                <!-- v-model="chalansForm.challan" -->
                <input
                  type="file"
                  class="form-control"
                  @change="UploadChallan"
                  placeholder="challan"
                  id="challan"
                />
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="chalansForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Expire Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="chalansForm.expireDate"
                  placeholder="expireDate"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Validity</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.validity"
                  placeholder="validity"
                />
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading == true">
    <Spinner />
  </div>
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/admin/comman/Spinner.vue";
import moment from "moment";
import Banner from "../../../components/admin/comman/Banner.vue";
// import Form from "vform";
import jQuery from "jquery";
// var _ = require("lodash");
let $ = jQuery;
export default {
  name: "AdminProject",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      serviceModules: [],
      taskProgress: 0,
      msgFromPublic: {
        client_service_id: null,
        type: "",
        touser_id: null,
        touser_type: "",
        remark: "",
        service_id: null,
        comment: "",
      },
      requestdocuments: [],
      loading: false,
      challans: [],
      doccUploadByretailer: [],
      services: [],
      project: {},
      userData: {},
      allDoccs: [],

      certificateForm: {
        service_id: null,
        client_service_id: "",
        title: "",
        note: "",
        certificateAthority: "",
        certificateNo: "",
        expireDate: "",
        date: "",
        certificate: "",

        imagePrefix: "certificate",
      },

      chalansForm: {
        imagePrefix: "challan",
        client_service_id: "",
        service_id: null,

        title: "",
        challanAthority: "",
        challanNo: "",
        date: "",
        expireDate: "",
        validity: "",
        challan: "",
        amt: "",
        note: "",
        remark: "",
        status: "",
        ipAddress: "",
        thumbnail: "yes",
      },
      clientDoccumentsfrom: {
        title: "",
      },
      upgradeData: [],
      upgradeForm: {
        imagePrefix: "Upgrade",
        client_service_id: "",
        service_id: null,

        title: "",

        date: "",
        document: "",
        amt: "",
        note: "",
        remark: "",
        status: "",
        ipAddress: "",
      },

      uploadDocc_form: {
        client_service_id: null,
        service_document_id: null,
        alt: "",
        caption: "",
        serviceDocument: "",
        cwidth: "",
        cheight: "",
        cx: "",
        cy: "",
      },
      upgradeEdit: "false",
      upgradesData: [],
      extensionCertificate: "",
      msgImgError: "",
      extensionChallan: "",
      totalAmtChallan: 0,
      TasksData: [],
      Tasks: [],
      comments: [],
      commentsprivates: [],
      replyBox: "false",
      replyDataComment: "",
      srvMod: [],
    };
  },
  methods: {
    progressForProject() {
      var complete = 0;
      for (var i = 0; i < this.Tasks.length; i++) {
        if (this.Tasks[i].status == "complete") {
          complete += 1;
        }
      }
      var n = 100 / this.Tasks.length;

      this.taskProgress = parseInt(n * complete);
      return this.taskProgress;
    },
    replyData(value) {
      this.replyBox = "true";
      console.log(value.comment);
      this.replyDataComment = value.comment;

      this.msgFromPublic.touser_id = value.user.id;
      this.msgFromPublic.touser_type = "professional";
      console.log(this.msgFromPublic);
    },
    // comment area
    getAllComments() {
      this.loading = true;
      this.$axios
        .get(`admin/clientservicecomment?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.comments = res.data.data.data;
        });

      this.$axios
        .get(`admin/clientservicecomment?type=private`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.commentsprivates = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    privateTypeSand(user) {
      console.log(user);
      if (user == "retailer") {
        this.msgFromPublic.touser_id = this.project.retailer.id;
        this.msgFromPublic.touser_type = "retailer";
      }
      if (user == "admin") {
        this.msgFromPublic.touser_id = JSON.parse(
          localStorage.getItem("userData")
        ).super_admin_id;
        this.msgFromPublic.touser_type = "superAdmin";
      }
      console.log(this.msgFromPublic);
    },
    sandMessagePublick(type) {
      var userData = {};
      userData = JSON.parse(localStorage.getItem("userData"));
      console.log(userData);
      // if (type == "private") {
      //   this.msgFromPublic.touser_id = userData.id;
      //   if (userData.role) {
      //     this.msgFromPublic.touser_type = userData.role.name;
      //   }
      // }

      this.msgFromPublic.type = type;
      this.msgFromPublic.client_service_id = this.project.id;
      this.msgFromPublic.service_id = this.project.service_id;
      console.log(this.project);
      console.log(this.msgFromPublic);

      this.loading = true;
      this.$axios
        .post(`admin/clientservicecomment`, this.msgFromPublic, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Comment Sand Successfully", {
            autoClose: 1000,
          });
          this.msgFromPublic = {};
          this.getAllComments();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response.data.error.service_id[0]);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error.service_id[0],
          });
        });
    },

    dateTime(value) {
      value;
      // console.log("========");
      // console.log(moment().format(value));

      return moment(value).format("DD-MM-YYYY");
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    deleteChallans(value) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`admin/clientservicechallan/${value.id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.accessToken,
                },
              })
              .then((res) => {
                res;

                toast.success("Challan Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.getChalans();
              });
          }
        });
    },
    loadTask() {
      this.$axios
        .get(
          `admin/clientserviceprofessionalstep?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.Tasks = res.data.data.data;
        });
    },
    findImgUsedr(user, user_type) {
      var img = this.$store.state.placeholderImg;

        if(user_type == 'superAdmin'){
          user_type = 'superadmin'
        }

      if (user.photo != null) {
        img =
          this.$store.state.imgUrl +
          "/" +
          user_type +
          "/" +
          user.id +
          "/thumbs/" +
          user.photo;
      } else {
        img = this.$store.state.placeholderImg;
      }
      return img;
    },
    openWindowClientDoccRequest(docc) {
      var url = "";
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        this.project.id +
        "/thumbs/" +
        docc.attachment
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          this.project.id +
          "/thumbs/" +
          docc.attachment;
      }

      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        this.project.id +
        "/thumbs/" +
        docc.attachment
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          this.project.id +
          "/" +
          docc.attachment;
      }

      window.open(`${url}`);
    },
    saveTask(event, task) {
      var form = {};
      if (event.target.checked == true) {
        console.log(event.target.checked);
        console.log(task);

        form.status = "complete";
        form.remark = "";
      }
      if (event.target.checked == false) {
        form.status = "pendding";
        form.remark = "";
      }
      this.loading = true;
      this.$axios
        .post(`admin/clientserviceprofessionalstep/status/${task.id}`, form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("The Task successfully Done", {
            autoClose: 1000,
          });
          this.getProfessionalTaskCompleted();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        });
    },

    getChalans() {
      var amt = 0;
      this.$axios
        .get(`admin/clientservicechallan?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          this.challans = res.data.data.data;
          for (var i = 0; i < this.challans.length; i++) {
            amt += parseInt(this.challans[i].amt);
          }

          this.totalAmtChallan = amt;
        });
    },
    uploadUpgrade(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.upgradeForm.document = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#uploadUpgrade").value;
        this.extensionChallan = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }
        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    UploadChallan(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.chalansForm.challan = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#challan").value;
        this.extensionChallan = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }
        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    UploadCertificate(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.certificateForm.certificate = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#certificate").value;
        this.extensionCertificate = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }

        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    createChalans() {
      this.chalansForm.client_service_id = this.project.id;
      this.chalansForm.service_id = this.project.service_id;

      // console.log(this.chalansForm);

      const formData = new FormData();
      const keys = Object.keys(this.chalansForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.chalansForm[keys[n]] !== null) {
          formData.append(keys[n], this.chalansForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("admin/clientservicechallan", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getChalans();
          $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // res
          console.log(error.response);
        });
    },

    clientServiceDoccumentRequestGet() {
      this.loading = true;
      this.$axios
        .get(
          `admin/clientservicerequestdocument?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log("*************************");
          // console.log(res.data.data.data);
          this.requestdocuments = res.data.data.data;
          // console.log("*************************");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    clientServiceDoccumentRequestPost() {
      this.clientDoccumentsfrom.client_service_id = this.project.id;
      this.clientDoccumentsfrom.service_id = this.project.service_id;

      // console.log(this.chalansForm);

      this.loading = true;
      this.$axios
        .post("admin/clientservicerequestdocument", this.clientDoccumentsfrom, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The Request was sent successfully", {
            autoClose: 1000,
          });
          this.clientServiceDoccumentRequestGet();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // res
          console.log(error.response);
        });
    },
    getUpgrade() {
      this.loading = true;
      this.$axios
        .get(`admin/clientserviceupgrade?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log("*************************");
          // console.log(res.data.data.data);
          this.upgradesData = res.data.data.data;
          // console.log("*************************");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    createUpgrade() {
      this.upgradeForm.client_service_id = this.project.id;
      this.upgradeForm.service_id = this.project.service_id;

      // console.log(this.upgradeForm);

      const formData = new FormData();
      const keys = Object.keys(this.upgradeForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.upgradeForm[keys[n]] !== null) {
          formData.append(keys[n], this.upgradeForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("admin/clientserviceupgrade", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getUpgrade();
          this.upgradeForm = {};
          $("#upgradeModel").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    upgradeDelete(upgrade) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`admin/clientserviceupgrade/${upgrade.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Deleted Succesfully", {
                  autoClose: 1000,
                });
                this.getUpgrade();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                error;
              });
          }
        });
    },

    upgradeDataAsign(upgrade) {
      // console.log(upgrade);
      this.upgradeEdit = "true";
      this.upgradeForm.id = upgrade.id;
      this.upgradeForm.client_service_id = upgrade.client_service_id;
      this.upgradeForm.service_id = upgrade.service_id;
      this.upgradeForm.title = upgrade.title;
      this.upgradeForm.date = upgrade.date;
      this.upgradeForm.amt = upgrade.amt;
      this.upgradeForm.note = upgrade.note;
      // console.log(this.upgradeForm);
    },
    updateUpgrade() {
      this.upgradeForm.client_service_id = this.project.id;
      this.upgradeForm.service_id = this.project.service_id;

      // console.log(this.upgradeForm);

      const formData = new FormData();
      const keys = Object.keys(this.upgradeForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.upgradeForm[keys[n]] !== null) {
          formData.append(keys[n], this.upgradeForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post(`admin/clientserviceupgrade/${this.upgradeForm.id}?_method=PUT`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;

          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getUpgrade();
          this.upgradeForm = {};
          $("#upgradeModel").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },

    createCertificateByPrfesional() {
      this.certificateForm.client_service_id = this.project.id;
      this.certificateForm.service_id = this.project.service_id;
      // console.log(this.certificateForm);

      const formData = new FormData();
      const keys = Object.keys(this.certificateForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.certificateForm[keys[n]] !== null) {
          formData.append(keys[n], this.certificateForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("admin/clientservicecertificate", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          // this.getChalans();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    uploadDocuments(event, docc) {
      this.uploadDocc_form.serviceDocument = event.target.files["0"];
      this.uploadDocc_form.service_document_id = docc.id;
      this.uploadDocc_form.client_service_id = this.$route.params.id;
      this.uploadDocc_form.alt = docc.document;
      this.uploadDocc_form.caption = docc.document;

      // console.log('-------------------------------------------------------------------------------')

      const formData = new FormData();
      const keys = Object.keys(this.uploadDocc_form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.uploadDocc_form[keys[n]] !== null) {
          formData.append(keys[n], this.uploadDocc_form[keys[n]]);
        }
      }
      this.$axios
        .post("retailer/clientservicedocument", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          // alert(res.data)
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "The form was sent successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          this.clientserviceDocumentLoad();
        });
    },
    clientserviceDocumentLoad() {
      this.$axios
        .get(`admin/clientservicedocument?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          //   console.log(res.data)
          this.doccUploadByretailer = res.data.data.data;
        });
    },
    LoadDocuments() {
      // var service_id = service.id;

      this.$axios
        .get(
          `admin/clientservicecertificate?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          //   console.log(res.data)
          this.allDoccs = res.data.data.data;
        });
    },

    loadUserApi() {
      this.$axios
        .post(
          `admin/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data)
          this.userData = res.data;
        });
    },
    downloadImg(link) {
      var url;
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/thumbs/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/thumbs/" +
          link.document;
      }
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/" +
          link.document;
      }
      // console.log('downloading : ', url);
this.openWindow(url)
      // return url;
    },
    loadProject() {
      this.$axios
        .get(`admin/clientservice/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.project = res.data.data;
          this.loadModules();
          // console.log(res.data.data);
        });
    },
    loadModules() {
      console.log(this.project);
      var mod = [];
      mod = JSON.parse(this.project.serviceModules);
      for (var i = 0; i < mod.length; i++) {
        var data = `&serviceModulesIds[]=${mod[i]}`;
        this.srvMod.push(data);
      }
      this.$axios
        .get(
          `admin/servicemodule?client_service_id=${this.$route.params.id}${this.srvMod}`,

          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.serviceModules = res.data.data.data;
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.adminPageTitles.clientsServiceView
    console.log(this.$store.state.adminPageTitles.clientsServiceView)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadProject();
      this.loadUserApi();
      this.getChalans();
      this.getUpgrade();
      this.loadPageTitle()
      this.LoadDocuments();
      this.loadTask();
      this.clientServiceDoccumentRequestGet();
      this.getAllComments();
      this.clientserviceDocumentLoad();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

h4 {
  font-size: 1rem;
}
</style>
